<template>
  <div class="classification" v-if="hasResource('refund_management')">
    <div class="integral-refund">
      <div class="search-form">
        <el-button
          class="black-btn"
          type="primary"
          @click="exportInfo"
          :loading="isLoading"
          v-if="hasResource('refund_export')"
        >导出</el-button>

        <div class="dis-fix">
          <el-form :model="filter" :inline="true">
            <el-form-item label="订单号">
              <el-input v-model="filter.refundNumber" placeholder="输入订单号检索" clearable></el-input>
            </el-form-item>
            <el-form-item label="退款时间">
              <!-- <el-date-picker v-model="filter.refundTime" type="datetime" placeholder="选择日期时间"></el-date-picker> -->
              <el-date-picker
                style="width:168px"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="refundTime"
                type="datetimerange"
                range-separator="-"
                start-placeholder="请选择"
                end-placeholder="请选择"
                :clearable="false"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="原订单号">
              <el-input v-model="filter.order_number" placeholder="输入单号检索" clearable></el-input>
            </el-form-item>
            <el-form-item label="交易单号">
              <el-input v-model="filter.refund_transaction_id" placeholder="输入单号检索" clearable></el-input>
            </el-form-item>
            <el-form-item label="退款结果">
              <el-select v-model="refundStatusValue" placeholder="请选择" class="select-size">
                <el-option
                  v-for="item in refundStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="text-btn">
            <span class="serach-button" @click="handleQuery">查询</span>
            <span class="clear-button" @click="handleReset">清空</span>
          </div>
        </div>
      </div>
      <el-table
        :data="integralData"
        style="width:100%;"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="isTableLoading"
        :default-sort="{ prop: 'created_at', order: 'descending' }"
        @sort-change="sortChange"
      >
        <el-table-column type="selection" width="125"></el-table-column>
        <el-table-column prop="refund_number" label="退款订单号" width="185" sortable="custom"></el-table-column>
        <el-table-column prop="order_number" label="原订单号" width="185" sortable="custom">
          <template slot-scope="scope">
            <span class="cursor-pointer" @click="jumpToOrders(scope.row)">{{scope.row.order_number}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="refund_transaction_id" label="交易单号" width="280" sortable="custom"></el-table-column>
        <el-table-column prop="created_at" label="退款时间" width="185" sortable="custom"></el-table-column>
        <el-table-column prop="price" label="退款金额" width="185" sortable="custom">
          <template slot-scope="scope">
            <span>{{scope.row.price}}</span>
            <span v-if="scope.row.currency === 'USD'">美元</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="退款结果" width="165">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 1">退款成功</span>
            <span v-if="scope.row.status === 2">退款失败</span>
          </template>
        </el-table-column>

        <el-table-column>
          <template slot="header">
            <div style="padding-left:22px !important;">操作</div>
          </template>
          <template class="icon-btn">
            <el-button type="text" size="small">暂无</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="ta-r mg-t-18">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10,20,50]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import refundApi from "../../../api/order";
export default {
  data() {
    return {
      header: {
        url: "refunds",
      },
      filter: {
        refundNumber: "", //订单号

        orderNumber: "", // 交易单号/原订单
        order_number: "",
        refund_transaction_id: "",
        status: "", //状态
      },
      market: "",
      isTableLoading: false,
      integralData: [],
      refundStatus: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "退款成功",
        },
        {
          value: "2",
          label: "退款失败",
        },
      ],
      refundStatusValue: "",
      pagesize: 10,
      total: 0,
      currentPage: 1,
      order_by: "created_at",
      sort: "DESC",
      isLoading: false,
      exportData: "",
      refundTime: [],
    };
  },
  methods: {
    init() {
      this.getRefundList();
    },
    getRefundList() {
      this.isTableLoading = true;
      let data = {
        limit: this.pagesize,
        page: this.currentPage,
        order_by: this.order_by,
        sort: this.sort,
        filter: {
          refund_number:
            this.filter.refundNumber || this.$route.query.relation_order,
          refund_order_transaction: this.filter.orderNumber,
          status: this.refundStatusValue,
          created_at: {
            from: this.refundTime[0],
            to: this.refundTime[1],
          },
        },
      };
      this.exportData = data.filter;
      refundApi.refundList(data).then((res) => {
        if (res.data.code === 10200) {
          this.integralData = res.data.data.items;
          this.total = res.data.data.total;
          // this.getTime();
          if (this.integralData.length <= 0 && this.currentPage > 1) {
            this.currentPage = 1;
            this.getUserList();
          }
        } else {
          this.$$error(res.data.message);
        }
        this.isTableLoading = false;
      });
    },
    handleQuery() {
      this.currentPage = 1;
      this.isTableLoading = true;
      let data = {
        limit: this.pagesize,
        page: this.currentPage,
        filter: {
          refund_number:
            this.filter.refundNumber || this.$route.query.relation_order,
          refund_order_transaction: this.filter.orderNumber,
          order_number: this.filter.order_number,
          refund_transaction_id: this.filter.refund_transaction_id,
          status: this.refundStatusValue,
          created_at: {
            from: this.refundTime[0],
            to: this.refundTime[1],
          },
        },
      };
      this.exportData = data.filter;
      refundApi.refundList(data).then((res) => {
        if (res.data.code === 10200) {
          this.integralData = res.data.data.items;
          this.total = res.data.data.total;
          // this.getTime();
          if (this.integralData.length <= 0 && this.currentPage > 1) {
            this.currentPage = 1;
            this.getUserList();
          }
        } else {
          this.$$error(res.data.message);
        }
        this.isTableLoading = false;
      });
    },
    handleReset() {
      this.filter = {
        refundNumber: "",
        // refundTime: "",
        orderNumber: "",
        order_number: "",
        refund_transaction_id: "",
        status: "",
      };
      this.refundStatusValue = "";
      this.refundTime = [];
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.refundTime = [];
      this.getRefundList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.refundTime = [];
      this.getRefundList();
    },
    exportInfo() {
      this.isLoading = true;
      let data = this.exportData;
      refundApi.refundExport(data).then((res) => {
        if (res.data.code === 10200) {
          this.downloadFile(res.data.data.export_url);
        } else {
          this.$$error(res.data.message);
        }
        this.isLoading = false;
      });
    },
    getTime() {
      const end = new Date();
      const start = new Date(new Date().toLocaleDateString());
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      this.refundTime = [start, end];
    },
    sortChange(column, prop, order) {
      this.order_by = column.prop;
      this.sort = column.order == "descending" ? "DESC" : "ASC";
      this.getRefundList();
    },
    jumpToOrders(row) {
      this.$router.push({
        path: "/transactions/orders",
        query: {
          order_number: row.order_number,
        },
      });
    },
  },
  created() {
    this.init();
    //this.getTime();
  },
};
</script>
<style lang="less">
@import "./scss/index.less";
</style>